<template>
  <div class="navbar-container d-flex content align-items-center">
    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link class="nav-link" @click="toggleVerticalMenuActive">
          <feather-icon icon="MenuIcon" size="21" />
        </b-link>
      </li>
    </ul>

    <!-- Left Col -->
    <div
      class="bookmark-wrapper align-items-center flex-grow-1 d-none d-lg-flex"
    >
      <dark-Toggler class="d-none d-lg-block" />
    </div>

    <b-navbar-nav class="nav align-items-center ml-auto" v-if="!sockets">
      <button class="btn btn-primary" @click="$router.push('/calls2')">
        دخول الآن
      </button>
    </b-navbar-nav>
    <b-navbar-nav class="nav align-items-center ml-auto" v-if="sockets">
      <NotificationDropdown2 class="" />
      <NotificationDropdown class="" />
      <b-nav-item-dropdown
        right
        toggle-class="d-flex align-items-center dropdown-user-link"
        class="dropdown-user"
      >
        <template #button-content>
          <div class="d-sm-flex d-none user-nav">
            <p class="user-name font-weight-bolder mb-0">
              {{ user.name }}
            </p>
            <span class="user-status text-success" v-if="user.available">
              <i class="fa fa-check"></i>
              متوفر الآن
            </span>
            <span class="user-status text-warning" v-if="!user.available">
              <i class="fa fa-clock-o"></i>
              مشغول الآن
            </span>
          </div>
          <b-avatar
            size="40"
            variant="light-primary"
            badge
            v-if="user.available"
            :src="user.avatar"
            class="badge-minimal"
            badge-variant="success"
          />

          <b-avatar
            size="40"
            variant="light-warning"
            v-if="!user.available"
            :src="user.avatar"
            badge=""
            class="badge-minimal"
            badge-variant="warning"
          />
        </template>
        <b-dropdown-item
          v-if="!user.available"
          @click="changeMyStatus(true)"
          link-class="d-flex align-items-center text-success"
        >
          <feather-icon size="16" icon="CheckIcon" class="mr-50" />
          <span> متوفر الآن </span>
        </b-dropdown-item>
        <b-dropdown-item
          v-if="user.available"
          @click="changeMyStatus(false)"
          link-class="d-flex align-items-center text-danger"
        >
          <feather-icon size="16" icon="ClockIcon" class="mr-50" />
          <span> مشغول الآن </span>
        </b-dropdown-item>
        <b-dropdown-item
          @click="$router.push('/logout')"
          link-class="d-flex align-items-center"
        >
          <feather-icon size="16" icon="LogOutIcon" class="mr-50" />
          <span>تسجيل الخروج</span>
        </b-dropdown-item>
      </b-nav-item-dropdown>
    </b-navbar-nav>
  </div>
</template>

<script>
import {
  BLink,
  BNavbarNav,
  BNavItemDropdown,
  BDropdownItem,
  BDropdownDivider,
  BAvatar,
} from "bootstrap-vue";
import DarkToggler from "@core/layouts/components/app-navbar/components/DarkToggler.vue";
import NotificationDropdown from "@core/layouts/components/app-navbar/components/NotificationDropdown.vue";
import NotificationDropdown2 from "@core/layouts/components/app-navbar/components/NotificationDropdown2.vue";
export default {
  data() {
    return {
      user: JSON.parse(localStorage.getItem("user")),
    };
  },
  methods: {
    changeMyStatus(status) {
      var g = this;
      $.post(api + "/console/general/available", {
        jwt: this.user.jwt,
        status: status,
      }).then(function (r) {
        g.user.available = !JSON.parse(r).count.available;
      });
    },
  },
  components: {
    BLink,
    BNavbarNav,
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,
    BAvatar,

    // Navbar Components
    NotificationDropdown,
    NotificationDropdown2,
    DarkToggler,
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
};
</script>
