<template>
  <div>
    <b-nav-item-dropdown
      class="dropdown-notification mr-25"
      menu-class="dropdown-menu-media"
      right
    >
      <template #button-content>
        <feather-icon
          :badge="notificationsUnread"
          badge-classes="bg-danger"
          class="text-body"
          icon="MessageSquareIcon"
          size="21"
        />
      </template>

      <!-- Notifications -->
      <div class="scrollable-container media-list scroll-area" tagname="li">
        <div class="col-12 g">
          <button class="btn btn-primary btn-sm" @click="getChats()">
            <i class="fa fa-refresh"></i> تحديث
          </button>
        </div>
        <!-- Account Notification -->
        <b-link
          v-for="notification in notifications"
          :key="notification.subtitle"
          v-b-modal.chat-modal
          @click="
            chat = notification;
            getMessages(notification._id);
          "
        >
          <b-media
            :class="
              notification.admin_seen
                ? 'chatlink '
                : ' chatlink bg-light-danger border-right-danger'
            "
          >
            <template #aside>
              <b-avatar
                size="32"
                :src="avatar(notification.service)"
                :variant="notification.admin_seen ? '' : 'light-danger'"
              />
            </template>
            <p class="media-heading">
              <span
                :class="
                  'font-weight-bolder ' + notification.admin_seen
                    ? ''
                    : ' text-danger'
                "
              >
                <span
                  class="btn btn-sm btn-success"
                  style="border-radius: 0px; padding: 3px"
                  v-if="notification.client.online"
                  >متصل الآن</span
                >
                {{ notification.client.name }}
              </span>
            </p>
            <small
              class="notification-text"
              v-html="notification.last_message"
            ></small>
          </b-media>
        </b-link>
      </div>
    </b-nav-item-dropdown>
    <!-- modal -->
    <b-modal id="chat-modal" size="lg" hide-footer>
      <div class="col-12 c" style="position: relative" v-if="chat.client">
        <a :href="'/clients/client/' + chat.client_id" target="_blank">
          <b-media
            :class="
              chat.admin_seen
                ? 'chatlink '
                : ' chatlink bg-light-danger border-right-danger'
            "
          >
            <template #aside>
              <b-avatar
                size="32"
                :src="avatar(chat.service)"
                :variant="chat.admin_seen ? '' : 'light-danger'"
              />
            </template>
            <p class="media-heading">
              <span
                :class="
                  'font-weight-bolder ' + chat.admin_seen ? '' : ' text-danger'
                "
              >
                <span
                  class="btn btn-sm btn-success"
                  style="border-radius: 0px; padding: 3px"
                  v-if="chat.client.online"
                  >متصل الآن</span
                >
                {{ chat.client.name }}
              </span>
            </p>
          </b-media>
        </a>
        <div class="chat-wall g">
          <div
            v-for="msg in messages"
            :class="'col-12 ' + msg.message_from + ' msg'"
            :key="msg._id"
          >
            <span v-if="!msg.file && !msg.sound" v-html="msg.content"></span>
            <span v-if="msg.file">
              <span v-if="msg.file.includes('.pdf')">
                <a :href="msg.file" target="_blank" class="btn btn-info">
                  <i class="fa fa-file"></i>
                  عرض الملف
                </a>
              </span>
              <span v-if="!msg.file.includes('.pdf')">
                <a :href="msg.file" target="_blank">
                  <img :src="msg.file" style="width: 50%" alt="" />
                </a>
              </span>
            </span>
            <span v-if="msg.sound">
              <audio controls>
                <source :src="msg.sound" type="audio/ogg" />
              </audio>
            </span>
            <br />
            <small
              >{{ msg.date }}
              <span
                class="fa fa-check"
                v-if="!msg.client_seen && msg.message_from == 'admin'"
                >تم الارسال</span
              >
              <span
                class="fa fa-eye"
                v-if="msg.client_seen && msg.message_from == 'admin'"
              >
                تمت رؤيتها &nbsp;</span
              >
              <span v-if="msg.admin_id"
                >-
                <img
                  :src="admins[msg.admin_id].avatar"
                  style="width: 20px; height: 20px"
                  alt=""
                />
                {{ admins[msg.admin_id].name }}</span
              >
            </small>
          </div>
        </div>
        <div class="write-message">
          <div class="input-group mb-3">
            <input
              type="text"
              class="form-control"
              placeholder="اكتب الرسالة..."
              aria-label="Username"
              aria-describedby="basic-addon1"
              style="border-radius: 0px"
              v-model="message"
              v-on:keyup.enter="sendMessageText()"
            />
            <div class="input-group-prepend">
              <span
                class="btn btn-primary"
                @click="sendMessageText()"
                style="border-radius: 0px"
                ><i class="fa fa-paper-plane"></i
              ></span>
            </div>
          </div>
          <div class="row">
            <div class="col-12" id="message-confirm-sound"></div>
            <div class="col-12 col-lg-4" style="padding: 1px" v-if="!recording">
              <button
                @click="record()"
                class="btn btn-block btn-success"
                style="border-radius: 0px"
              >
                <i class="fa fa-microphone"></i>
                رسال صوتية
              </button>
            </div>
            <div class="col-12 col-lg-4" style="padding: 1px" v-if="recording">
              <button
                @click="stopRecord()"
                class="btn btn-block btn-danger"
                style="border-radius: 0px"
              >
                {{ timer }}
                <br />
                <i class="fa fa-microphone-slash"></i>
                ايقاف التسجيل
              </button>
            </div>
            <div class="col-12 col-lg-8" style="padding: 1px">
              <button
                @click="attachment()"
                class="btn btn-block btn-default"
                style="border-radius: 0px"
              >
                <i class="fa fa-upload"></i>
                إرسال ملف/صورة
              </button>
            </div>
            <div class="col-12" id="progress-wrp">
              <div class="progress-bar"></div>
              <div class="status">0%</div>
            </div>
          </div>
        </div>
      </div></b-modal
    >
  </div>
</template>

<script>
import {
  BNavItemDropdown,
  BBadge,
  BMedia,
  BLink,
  BAvatar,
  BButton,
  BModal,
  VBModal,
  BFormCheckbox,
} from "bootstrap-vue";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import Ripple from "vue-ripple-directive";

export default {
  components: {
    BNavItemDropdown,
    BBadge,
    BMedia,
    BLink,
    BAvatar,
    BModal,
    VuePerfectScrollbar,
    BButton,
    BFormCheckbox,
  },
  directives: {
    Ripple,
    "b-modal": VBModal,
  },
  data() {
    return {
      services: [],
      admins: [],
      user: JSON.parse(localStorage.getItem("user")),
      notifications: [],
      notificationsUnread: "🕖",
      chat: {},
      messages: [],
      recording: false,
      timer: "0:0",
      madiaRecorder: null,
      audioChunks: [],
      inta: null,
      lastSound: null,
      message: null,
    };
  },
  created() {
    var g = this;
    $.post(api + "/console/general/services", {
      jwt: this.user.jwt,
    }).then(function (r) {
      g.services = {};
      JSON.parse(r).response.forEach((a) => {
        g.services[a.code] = a;
      });
    });
    $.post(api + "/console/general/admins-names", {
      jwt: this.user.jwt,
    }).then(function (r) {
      g.admins = {};
      JSON.parse(r).response.forEach((a) => {
        g.admins[a._id] = a;
      });
    });
    g.getChats();
    g.sockets.subscribe("new-message", (d) => {
      var audio = new Audio(
        "https://cdn-static.brmja.com/storage/uploads/files/620511d8d5536534353593.mp3"
      );
      audio.play();
      if ($("#chat-modal").is(":visible") && d == g.chat._id) {
        g.getMessages(d);
      }
      g.getChats();
    });
    g.sockets.subscribe("new-message-silent", (d) => {
      if ($("#chat-modal").is(":visible") && d == g.chat._id) {
        g.getMessages(d);
      }
      g.getChats();
    });
    setInterval(() => {
      g.getChats();
    }, 10000);
  },
  methods: {
    activeLinks(text) {
      var Rexp =
        /((http|https|ftp):\/\/[\w?=&.\/-;#~%-]+(?![\w\s?&.\/;#~%"=-]*>))/g;
      return text.replace(Rexp, "<a href='$1' target='_blank'>$1</a>");
    },
    getMessages(id) {
      var g = this;
      $.post(api + "/console/chats/messages", {
        jwt: this.user.jwt,
        chat_id: id,
      }).then(function (r) {
        g.messages = r.response;
        setTimeout(() => {
          var scroll = $(".chat-wall");
          scroll.animate({ scrollTop: scroll.prop("scrollHeight") });
        }, 10);
      });
    },
    avatar(id) {
      return this.services[id]?.image;
    },
    getChats() {
      var g = this;
      $.post(api + "/console/chats/chats", {
        jwt: this.user.jwt,
      }).then(function (r) {
        g.notifications = r.response;
        g.notificationsUnread = 0;
        r.response.forEach(function (a) {
          if (!a.admin_seen) {
            g.notificationsUnread = g.notificationsUnread + 1;
          }
        });
      });
    },
    record() {
      var g = this;
      g.audioChunks = [];
      g.timer = "0:0";
      navigator.mediaDevices.getUserMedia({ audio: true }).then((stream) => {
        g.madiaRecorder = new MediaRecorder(stream);
        var madiaRecorder = g.madiaRecorder;
        madiaRecorder.start();
        g.recording = true;
        var msec = 0;
        var sec = 0;
        var min = 0;
        g.inta = setInterval(clock, 1000);
        function clock() {
          msec += 1;
          if (msec == 60) {
            sec += 1;
            msec = 0;
            if (sec == 60) {
              sec = 0;
              min += 1;
            }
          }
          g.timer = sec + ":" + msec;
        }
        madiaRecorder.addEventListener("dataavailable", function (event) {
          g.audioChunks.push(event.data);
        });
      });
    },
    stopRecord() {
      var g = this;
      clearInterval(g.inta);
      var madiaRecorder = g.madiaRecorder;
      g.madiaRecorder.stop();
      g.recording = false;
      madiaRecorder.addEventListener("stop", function () {
        var audioBlob = new Blob(g.audioChunks);
        var fileReader = new FileReader();
        fileReader.readAsDataURL(audioBlob);
        fileReader.onloadend = function () {
          var base64String =
            "data:audio/ogg;base64," + fileReader.result.split("base64,")[1];
          g.lastSound = base64String;
          $("#message-confirm-sound").html(`
            <div class='row'>
              <div class='col-12 col-lg-7'>
                <audio controls autoplay>
                <source src="${base64String}" type="audio/ogg">
              Your browser does not support the audio element.
              </audio>
              </div>
              <div class='col-12 col-lg-5'>
                <button class='btn btn-info'>ارسال التسجيل <i class='fa fa-paper-plane'></i></button>
                <br><br>
              </div>
            </div>
          `);
          $("#message-confirm-sound button").click(function () {
            $("#message-confirm-sound").html(" ");
            g.sendSound();
          });
        };
      });
    },
    sendSound() {
      var g = this;
      $.post(api + "/console/chats/send", {
        jwt: g.user.jwt,
        message: "🔊 رسالة صوتية",
        sound: g.lastSound,
        chat_id: g.chat._id,
      }).then(function () {
        g.getMessages(g.chat._id);
        g.message = null;
      });
    },
    attachment() {
      $("body").append(`<input
              type="file"
              id="ingredient_file" style='display:none'
            />`);
      var g = this;

      var Upload = function (file) {
        this.file = file;
      };

      Upload.prototype.getType = function () {
        return this.file.type;
      };
      Upload.prototype.getSize = function () {
        return this.file.size;
      };
      Upload.prototype.getName = function () {
        return this.file.name;
      };
      Upload.prototype.doUpload = function () {
        var that = this;
        var formData = new FormData();

        // add assoc key values, this will be posts values
        formData.append("file", this.file, this.getName());
        formData.append("jwt", g.user.jwt);
        $.ajax({
          type: "POST",
          url: api + "/console/chats/upload",
          xhr: function () {
            var myXhr = $.ajaxSettings.xhr();
            if (myXhr.upload) {
              myXhr.upload.addEventListener(
                "progress",
                that.progressHandling,
                false
              );
            }
            return myXhr;
          },
          success: function (data) {
            $("#progress-wrp").fadeOut("slow");
            $("#ingredient_file").remove();
            g.loading = true;
            $.post(api + "/console/chats/send", {
              jwt: g.user.jwt,
              message: "📷 صورة/ملف",
              file: data.response,
              chat_id: g.chat._id,
            }).then(function () {
              g.getMessages(g.chat._id);
              g.message = null;
            });
          },
          error: function (error) {
            // handle error
            $("#progress-wrp").fadeOut("slow");
            $("#ingredient_file").remove();
            alert("حدث خطأ، ربما صيغة الملف غير مدعومة");
          },
          async: true,
          data: formData,
          cache: false,
          contentType: false,
          processData: false,
          timeout: 60000,
        });
      };

      Upload.prototype.progressHandling = function (event) {
        var percent = 0;
        var position = event.loaded || event.position;
        var total = event.total;
        var progress_bar_id = "#progress-wrp";
        if (event.lengthComputable) {
          percent = Math.ceil((position / total) * 100);
        }
        // update progressbars classes so it fits your code
        $(progress_bar_id + " .progress-bar").css("width", +percent + "%");
        $(progress_bar_id + " .status").text(percent + "%");
      };
      $("#ingredient_file").click();
      $("#ingredient_file").on("change", function (e) {
        $("#progress-wrp").fadeIn("slow");
        var file = $(this)[0].files[0];
        var upload = new Upload(file);

        // maby check size or type here with upload.getSize() and upload.getType()

        // execute upload
        upload.doUpload();
      });
    },
    sendMessageText() {
      var g = this;
      if (g.message) {
        if (g.message.trim() != "") {
          $.post(api + "/console/chats/send", {
            jwt: this.user.jwt,
            message: this.activeLinks(this.message),
            chat_id: this.chat._id,
          }).then(function () {
            g.getMessages(g.chat._id);
            g.message = null;
          });
        }
      }
    },
  },
  setup() {
    /* eslint-disable global-require */
    // const notifications = [
    //   {
    //     title: "Congratulation Sam 🎉",
    //     avatar: require("@/assets/images/avatars/6-small.png"),
    //     subtitle: "Won the monthly best seller badge",
    //     type: "light-success",
    //   },
    //   {
    //     title: "New message received",
    //     avatar: require("@/assets/images/avatars/9-small.png"),
    //     subtitle: "You have 10 unread messages",
    //     type: "light-info",
    //   },
    //   {
    //     title: "Revised Order 👋",
    //     avatar: "MD",
    //     subtitle: "MD Inc. order updated",
    //     type: "light-danger",
    //   },
    // ];

    const perfectScrollbarSettings = {
      maxScrollbarLength: 60,
      wheelPropagation: false,
    };

    return {
      perfectScrollbarSettings,
    };
  },
};
</script>

<style>
.border-right-danger {
  border-left: 3px solid red !important;
}
.chatlink:hover {
  background-color: #ddd !important;
}
.scrollable-container {
  max-height: 500px !important;
  overflow: hidden;
  overflow-y: scroll;
}
</style>

<style scoped>
.chat-wall {
  width: 100% !important;
  margin: 0px auto;
  border: 2px solid #ddd;
  border-radius: 5px 5px 0px 0px;
  overflow-y: scroll;
  height: 400px;
}
.write-message,
.write-message .row {
  margin: 0px auto;
  width: 100% !important;
}
.admin {
  background: #adddd0;
  text-align: left !important;
}
.client {
  background: #ddd;
  text-align: right !important;
}
.msg {
  padding: 5px;
  border-radius: 5px 0px;
  margin-top: 5px;
}
.loading {
  position: absolute;
  right: 0px;
  top: 0px;
}
</style>
<style scoped>
#progress-wrp {
  border: 1px solid #0099cc;
  padding: 1px;
  position: relative;
  height: 30px;
  border-radius: 3px;
  margin: 10px;
  text-align: left;
  background: #fff;
  box-shadow: inset 1px 3px 6px rgba(0, 0, 0, 0.12);
  display: none;
}

#progress-wrp .progress-bar {
  height: 100%;
  border-radius: 3px;
  background-color: greenyellow;
  width: 0;
  box-shadow: inset 1px 1px 10px rgba(0, 0, 0, 0.11);
}

#progress-wrp .status {
  top: 3px;
  left: 50%;
  position: absolute;
  display: inline-block;
  color: #000000;
}
</style>
