var arr = [
  {
    title: 'الرئيسية',
    route: 'home',
    icon: 'HomeIcon',
  },
  {
    title: 'المكالمات',
    route: 'calls2',
    icon: 'PhoneIncomingIcon',
  },
  {
    title: 'المحادثات',
    route: 'chats',
    icon: 'MessageSquareIcon',
  },
  {
    title: 'التذاكر',
    route: 'tickets',
    icon: 'InboxIcon',
  },
  {
    title: 'الجلسات',
    route: 'sessions',
    icon: 'ClockIcon',
  },
  {
    title: 'المهام',
    route: 'tasks',
    icon: 'CheckSquareIcon',
  },
  {
    title: 'العملاء',
    route: 'clients',
    icon: 'UsersIcon',
  },
  {
    title: 'المشرفين',
    route: 'admins',
    icon: 'UserIcon',
  },
  {
    title: 'الخدمات',
    route: 'services',
    icon: 'GridIcon',
  },
], arr2 = [];

arr.forEach(function (e) {

  arr2.push(e)

})


export default arr2
